import dashboardIcon from '../../../assets/icons/dashboard.svg';
import userManagementIcon from '../../../assets/icons/sidePanelUserManagement.svg';
import procurementIcon from '../../../assets/icons/sidePanelProcurement.svg';
import salesIcon from '../../../assets/icons/sidePanelSales.svg';
import inventoryIcon from '../../../assets/icons/dashboard.svg';
import catalogIcon from '../../../assets/icons/sidePanelCatalog.svg';
import offerManagementIcon from '../../../assets/icons/sidePanelOfferManagement.svg';
import reportAnalyticsIcon from '../../../assets/icons/sidePanelReportAnalytics.svg';
import mastersIcon from '../../../assets/icons/sidePanelMasters.svg';
import leadManagementIcon from '../../../assets/icons/sidePanelLeadManagement.svg';
import miscellaneousIcon from '../../../assets/icons/sidePanelMiscellaneous.svg';
import messageCenterIcon from '../../../assets/icons/sidePanelMessageCenter.svg';
import {
  ADMIN_ROUTES,
  CATALOGUE_TYPES,
  CLASS_TYPE,
  RESOURCES,
} from '../../../utils/constant';
import { makeRoute } from '../../../utils/helper';
import reportIcon from '../../../assets/icons/filledReport.svg'

export interface MenuItem {
  key: string;
  icon?: string;
  route?: string;
  disabled?: boolean;
  subList?: MenuItem[];
}

export const sideMenuBarOptions: MenuItem[] = [
  {
    key: RESOURCES.DASHBOARD,
    icon: dashboardIcon,
    route: '/customer-profile',
    disabled: true,
  },
  {
    key: RESOURCES.USERS_MANAGEMENT,
    icon: userManagementIcon,
    subList: [
      {
        key: RESOURCES.VENDOR_MANAGEMENT,
        route: '/vendors',
      },
      // {
      //   key: RESOURCES.CUSTOMER_MANAGEMENT,
      //   route: ADMIN_ROUTES.CUSTOMER_LIST,
      // },
      {
        key: RESOURCES.USER_MANAGEMENT,
        route: '/customer-profile',
        disabled: true,
      },
      {
        key: RESOURCES.ROLES_PERMISSION,
        route: '/catalogue',
        disabled: true,
      },
      {
        key: RESOURCES.CUSTOMER_MANAGEMENT_V2,
        route: '/customer-management/customers',
      },
      {
        key: RESOURCES.CUSTOMER_GROUP_MASTER,
        route: ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST,
      },
    ],
  },
  {
    key: RESOURCES.PROCUREMENT,
    icon: procurementIcon,
    disabled: false,
    subList: [
      // {
      //     key: RESOURCES.PURCHASE_DASHBOARD,
      //     route: "/catalogue",
      // },
      {
        key: RESOURCES.PURCHASE_REQUISITION,
        route: '/procurement/purchase-requisition',
      },
      {
        key: RESOURCES.PURCHASE_INDENT,
        route: '/procurement/indent',
      },
      {
        key: RESOURCES.REVERSE_AUCTION,
        subList: [
          {
            key: RESOURCES.REVERSE_AUCTION_RFQ,
            route: '/procurement/reverse-auction/reverse-auction-rfq',
          },
          {
            key: RESOURCES.VENDOR_DISCOVERY,
            route: '/procurement/reverse-auction/vendor-discovery',
          },
          {
            key: RESOURCES.LIVE_AUCTION,
            route: '/procurement/reverse-auction/live-auction',
          },
        ],
      },
      {
        key: RESOURCES.PURCHASE_ORDER,
        route: '/catalogue',
      },
      {
        key: RESOURCES.MATERIAL_INWARD,
        subList: [
          {
            key: RESOURCES.GATE_ENTRY,
            route: '/catalogue',
          },
          {
            key: RESOURCES.GRN,
            route: '/catalogue',
          },
          {
            key: RESOURCES.QUALITY_CHECK,
            route: '/catalogue',
          },
          {
            key: RESOURCES.PUTAWAY,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.VENDOR_PAYMENTS,
        route: '/catalogue',
      },
    ],
  },
  {
    key: RESOURCES.LEAD_MANAGEMENT,
    icon: leadManagementIcon,
    route: ADMIN_ROUTES.LEAD_VIEW,
  },
  // {
  //   key: RESOURCES.IMAGE_MASTER,
  //   icon: reportAnalyticsIcon,
  //   route: ADMIN_ROUTES.IMAGE_MASTER_LIST,
  // },
  {
    key: RESOURCES.MISCELLANEOUS,
    icon: miscellaneousIcon,
    subList: [
      {
        key: RESOURCES.BULK_UPLOAD_TRACKING,
        route: '/bulk-upload',
      },
    ],
  },
  {
    key: RESOURCES.SALES,
    icon: salesIcon,
    subList: [
      {
        key: RESOURCES.SALES_DASHBOARD,
        route: '/catalogue',
        disabled: true,
      },
      {
        key: RESOURCES.SALES_INDENT,
        route: '/catalogue',
        disabled: true,
      },
      {
        key: RESOURCES.FORWARD_AUCTION,
        disabled: true,
        subList: [
          {
            key: RESOURCES.SALES_RFQ,
            route: '/catalogue',
          },
          {
            key: RESOURCES.CUSTOMER_DISCOVERY,
            route: '/catalogue',
          },
          {
            key: RESOURCES.FORWARD_LIVE_AUCTION,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.SALES_LEAD,
        route: '/catalogue',
        disabled: true,
      },
      {
        key: RESOURCES.WHATSAPP_SALE,
        route: '/catalogue',
        disabled: true,
      },
      {
        key: RESOURCES.SALES_ORDER,
        route: ADMIN_ROUTES.ORDER_LIST,
      },
      {
        key: RESOURCES.MATERIAL_OUTWARD,
        disabled: true,
        subList: [
          {
            key: RESOURCES.DELIVERY_ORDER,
            route: '/catalogue',
          },
          {
            key: RESOURCES.SALES_INVOICE,
            route: '/catalogue',
          },
          {
            key: RESOURCES.GATE_OUT_ENTRY,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.CUSTOMER_PAYMENT,
        route: '/catalogue',
        disabled: true,
      },
    ],
  },
  {
    key: RESOURCES.INVENTORY,
    icon: inventoryIcon,
    subList: [
      {
        key: RESOURCES.STANDARD_PRIME,
        route: `/inventory/standard-prime?skuMode=${'STANDARD'}`,
      },
      {
        key: RESOURCES.NON_STANDARD_PRIME,
        route: `/inventory/non-standard-prime?skuMode=${'NONSTANDARD'}`,
      },
      {
        key: RESOURCES.SECONDARY,
        route: ADMIN_ROUTES.SECONDARY_INVENTORY_LIST,
      },
      {
        key: RESOURCES.STOCK_TRANSFER,
        route: '/catalogue',
        disabled: true,
      },
      {
        key: RESOURCES.PROCESSING,
        route: '/catalogue',
        disabled: true,
      },
    ],
  },
  {
    key: RESOURCES.MANAGE_QUOTATION,
    icon: inventoryIcon,
    route: ADMIN_ROUTES.INSTANT_QUOTATION_LIST,
  },
  {
    key: RESOURCES.CATALOGUE,
    icon: catalogIcon,
    subList: [
      {
        key: RESOURCES.CURRENT_INVENTORY,
        subList: [
          {
            key: RESOURCES.STANDARD_PRIME,
            route: makeRoute(ADMIN_ROUTES.CATALOGUE, {
              query: {
                catalogueType: CATALOGUE_TYPES.CURRENT_INVENTORY,
                category: CLASS_TYPE.STANDARD,
              },
            }),
          },
          {
            key: RESOURCES.NON_STANDARD_PRIME,
            route: makeRoute(ADMIN_ROUTES.CATALOGUE, {
              query: {
                catalogueType: CATALOGUE_TYPES.CURRENT_INVENTORY,
                category: CLASS_TYPE.NONSTANDARD,
              },
            }),
          },
          {
            key: RESOURCES.SECONDARY,
            route: makeRoute(ADMIN_ROUTES.CATALOGUE, {
              query: {
                catalogueType: CATALOGUE_TYPES.CURRENT_INVENTORY,
                category: CLASS_TYPE.SECONDARY,
              },
            }),
          },
          {
            key: RESOURCES.SECONDARY_LOTS,
            route: makeRoute(ADMIN_ROUTES.CATALOGUE, {
              query: {
                catalogueType: CATALOGUE_TYPES.MAKE_TO_ORDER,
                category: CLASS_TYPE.SECONDARY,
              },
            }),
          },
        ],
      },
      {
        key: RESOURCES.MAKE_TO_ORDER,
        subList: [
          {
            key: RESOURCES.STANDARD_PRIME,
            route: makeRoute(ADMIN_ROUTES.CATALOGUE, {
              query: {
                catalogueType: CATALOGUE_TYPES.MAKE_TO_ORDER,
                category: CLASS_TYPE.STANDARD,
              },
            }),
          },
          {
            key: RESOURCES.NON_STANDARD_PRIME,
            route: makeRoute(ADMIN_ROUTES.CATALOGUE, {
              query: {
                catalogueType: CATALOGUE_TYPES.MAKE_TO_ORDER,
                category: CLASS_TYPE.NONSTANDARD,
              },
            }),
          }
        ],
      },
    ],
  },
  {
    key: RESOURCES.REPORTS_AND_ANALYTICS,
    icon: reportIcon,
    disabled: false,
    subList: [
      {
        key: RESOURCES.VENDOR_COMPARISON,
        route: '/vendor-comparison',
      }
    ],
  },
  {
    key: RESOURCES.OFFER_MANAGEMENT,
    icon: offerManagementIcon,
    disabled: true,
    subList: [
      {
        key: RESOURCES.COMBO_OFFERS,
        route: '/catalogue',
      },
      {
        key: RESOURCES.PAYMENT_DISCOUNT,
        route: '/catalogue',
      },
      {
        key: RESOURCES.COUPON_MANAGEMENT,
        route: '/catalogue',
      },
    ],
  },
  {
    key: RESOURCES.REPORT_ANALYTICS,
    icon: reportAnalyticsIcon,
    disabled: true,
    subList: [
      {
        key: RESOURCES.INVENTORY,
        subList: [
          {
            key: RESOURCES.INVENTORY_AS_ON_DATE,
            route: '/catalogue',
          },
          {
            key: RESOURCES.INVENTORY_BIN_REPORT,
            route: '/catalogue',
          },
          {
            key: RESOURCES.INVENTORY_AGING_REPORT,
            route: '/catalogue',
          },
          {
            key: RESOURCES.ITEM_LEDGER_REPORT,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.PURCHASE,
        subList: [
          {
            key: RESOURCES.PURCHASE_CYCLE_REPORT,
            route: '/catalogue',
          },
          {
            key: RESOURCES.GRN_REPORT,
            route: '/catalogue',
          },
          {
            key: RESOURCES.PO_REPORT,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.SALES,
        subList: [
          {
            key: RESOURCES.SALES_CYCLE_REPORT,
            route: '/catalogue',
          },
          {
            key: RESOURCES.ORDER_DELIVERY_REPORT,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.AUCTION,
        subList: [
          {
            key: RESOURCES.PURCHASE_AUCTION_REPORT,
            route: '/catalogue',
          },
          {
            key: RESOURCES.SALES_AUCTION_REPORT,
            route: '/catalogue',
          },
        ],
      },
    ],
  },
  {
    key: RESOURCES.MASTERS,
    icon: mastersIcon,
    subList: [
      {
        key: RESOURCES.COMPANY,
        route: '/catalogue',
        disabled: true,
      },
      {
        key: RESOURCES.VAS_MASTER,
        subList: [
          {
            key: RESOURCES.VAS_MASTER_CATEGORIES,
            route: ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST,
          },
          {
            key: RESOURCES.VAS_MASTER_USCs,
            route: ADMIN_ROUTES.VAS_MASTER_USCS_LIST,
          },
          {
            key: RESOURCES.VAS_MASTER_MACHINES,
            route: ADMIN_ROUTES.VAS_MASTER_MACHINES_LIST,
          },
        ],
      },
      {
        key: RESOURCES.PRODUCTS,
        subList: [
          {
            key: RESOURCES.STANDARD_GRADE,
            route: ADMIN_ROUTES.STANDARD_LIST,
          },
          {
            key: RESOURCES.ATTRIBUTE,
            route: ADMIN_ROUTES.ATTRIBUTE_LIST,
          },
          {
            key: RESOURCES.CATEGORIES,
            route: ADMIN_ROUTES.CATEGORY,
          },
          {
            key: RESOURCES.MANUFACTURER,
            route: ADMIN_ROUTES.MANUFACTURER_LIST,
          },
          {
            key: RESOURCES.BRAND,
            route: ADMIN_ROUTES.BRAND_LIST,
          },
          {
            key: RESOURCES.UPC,
            route: ADMIN_ROUTES.UPC_LIST,
          },
          {
            key: RESOURCES.MPC,
            route: ADMIN_ROUTES.MPC_LIST,
          },
          // {
          //   key: RESOURCES.IMAGE_MASTER,
          //   icon: reportAnalyticsIcon,
          //   route: ADMIN_ROUTES.IMAGE_MASTER_LIST,
          // }
        ],
      },
      {
        key: RESOURCES.UPC,
        subList: [
          {
            key: RESOURCES.UPC_LISTING,
            route: ADMIN_ROUTES.UPC_LISTING,
          }
        ]
      },
      // {
      //   key: RESOURCES.CUSTOMER_GROUP_MASTER,
      //   route: ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST,
      // },
      {
        key: RESOURCES.WHATSAPP_TEMPLATE,
        route: ADMIN_ROUTES.WHATSAPP_TEMPLATE_LIST,
      },
      {
        key: RESOURCES.IMAGE_MASTER,
        route: ADMIN_ROUTES.IMAGE_MASTER_LIST,
      },
      {
        key: RESOURCES.WAREHOUSE,
        disabled: true,
        subList: [
          {
            key: RESOURCES.LOCATION,
            route: '/catalogue',
          },
          {
            key: RESOURCES.ZONE,
            route: '/catalogue',
          },
          {
            key: RESOURCES.BIN,
            route: '/catalogue',
          },
          {
            key: RESOURCES.WAREHOUSE_EMPLOYEE,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.TRANSPORT,
        disabled: true,
        subList: [
          {
            key: RESOURCES.TRANSPORT_METHOD,
            route: '/catalogue',
          },
          {
            key: RESOURCES.LOGISTIC_MANAGEMENT,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.QC,
        disabled: true,
        subList: [
          {
            key: RESOURCES.QC_MASTER,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.TAXES,
        disabled: true,
        subList: [
          {
            key: RESOURCES.GST,
            route: '/catalogue',
          },
          {
            key: RESOURCES.HSN,
            route: '/catalogue',
          },
        ],
      },
      {
        key: RESOURCES.TERMS_CONDITION,
        disabled: true,
        subList: [
          {
            key: RESOURCES.FOR_CATALOG,
            route: '/catalogue',
          },
          {
            key: RESOURCES.FOR_SALES,
            route: '/catalogue',
          },
          {
            key: RESOURCES.FOR_PROCUREMENT,
            route: '/catalogue',
          },
          {
            key: RESOURCES.FOR_ITEM,
            route: '/catalogue',
          },
        ],
      },
    ],
  },
  {
    key: RESOURCES.MESSAGE_CENTER,
    icon: messageCenterIcon,
    route: '/catalogue',
    disabled: true,
  },
];
